<template>
  <b-card
      class="presalesreturnbound-edit-wrapper"
  >
    <b-form
        @submit.prevent=""
        @reset.prevent=""
    >
      <b-row>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="团队名称"
              label-for="storeName"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="store_id"
                :options="storeArray.length===0?[]:storeArray"
                v-model="storeName"
                class="select-size-sm"
                placeholder="请选择业务团队"
                @input="changeSelect('store',$event)"
                :disabled='id !== 0'
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="终端客户"
              label-for="order_no"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="channel_name"
                :filterable="false"
                :options="channelArray.length===0?[]:channelArray"
                v-model="channelName"
                class="select-size-sm"
                placeholder="搜索终端客户"
                @input="changeSelect('channel',$event)"
                @search="loadChannelList"
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="退货编号"
              label-for="label"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="returnbound_no"
                size="sm"
                v-model="returnboundNo"
                readonly
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="业务人员"
              label-for="storeName"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="sales_id"
                :options="salesmanArray.length===0?[]:salesmanArray"
                v-model="salesName"
                class="select-size-sm"
                placeholder="请选择业务员"
                @input="changeSelect('salesman',$event)"
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="退入仓库"
              label-for="sales_warehouse"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="sales_warehouse"
                :options="warehouseArray.length === 0 ? [] : warehouseArray"
                v-model="warehouseName"
                @input="changeSelect('sales_warehouse',$event)"
                class="select-size-sm"
                placeholder="请选择退入仓库"
                :disabled='id !== 0'
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="销售权"
              label-for="reason"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="reason"
                :options="purchaseTeamArray"
                :clearable="false"
                v-model="purchaseTeamLabel"
                @input="changeSelect('purchase_team',$event)"
                class="select-size-sm"
                placeholder="请选择销售权"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="退货原因"
              label-for="reason"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="reason"
                :options="reasonArray"
                :clearable="false"
                v-model="reasonLabel"
                @input="changeSelect('reason',$event)"
                class="select-size-sm"
                placeholder="请选择退货原因"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="退货日期"
              label-for="label"
              label-size="sm"
              class="mb-1 required"
          >
            <flat-pickr
                v-model="returnTime"
                class="form-control"
                placeholder="请选择配送日期"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div style="margin-top: 50px;width: 98.6%;margin-left: 0.7%">
        <b-card
            no-body
            class="mb-0 list-table"
            style="box-shadow: none"
        >
          <div class="m-2">

            <!-- Table Top -->
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <span style="color: #00cfe8; font-weight: bolder; font-size: medium;">退 货 选 品</span>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <v-select
                    id="product"
                    :options="productArray"
                    @input="changeSelect('product',$event)"
                    v-model="productLabel"
                    @search="loadProductOptions"
                    label="name"
                    class="flex-grow-1 email-to-selector"
                    :close-on-select="false"
                    :filterable="false"
                    :dropdown-should-open="openSelect"
                    placeholder="请输入商品名称、69码搜索(请输入至少两个字符)"
                >
                  <template #option="{ name, code, ext }">
                    <span class="ml-50"> {{ name }} </span>
                    <span class="ml-50"> | {{ code }} </span>
                    <span class="ml-50"> |  可退数量: {{ ext.can_return_qty }} </span>
                  </template>
                  <template #selected-option="{ name }" style="background-color: cornsilk">
                    <span class="ml-50"> {{ name }}</span>
                  </template>
                </v-select>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-button
                    variant="info"
                    @click="quickAdd"
                >
                  加入明细
                </b-button>
              </b-col>
            </b-row>

          </div>
          <b-table
              sticky-header="100%"
              ref="refListTable"
              class="position-relative"
              :items="itemList"
              responsive
              hover
              small
              primary-key="id"
              :fields="tableColumns"
              show-empty
              empty-text="未找到记录"
              :tbody-tr-class="rowClass"
          >
            <template #cell(can_return_qty)="data">
              <div>{{ itemList[data.index].ext.can_return_qty }}</div>
            </template>
            <template #cell(return_qty)="data">
              <b-form-input
                  id="largeInput"
                  size="sm"
                  :type='"number"'
                  v-model="itemList[data.index].return_qty"
                  @blur="updateItem(data.item,data.index,$event)"
              />
            </template>
            <template #cell(return_price)="data">
              <b-form-input
                  id="largeInput"
                  size="sm"
                  :type='"number"'
                  v-model="itemList[data.index].return_price"
                  @blur="updateItem(data.item,data.index,$event)"
              />
              <span v-if="itemList[data.index].return_price === ''" style="color: red; size: A5">价格不能为空</span>
            </template>
            <template #cell(memo)="data">
              <b-form-input
                  id="largeInput"
                  size="sm"
                  v-model="itemList[data.index].memo"
                  @blur="addItemMemo(data.item,$event)"
              />
              <span
                  v-if="(itemList[data.index].return_price === 0 || itemList[data.index].return_price === '') && itemList[data.index].memo === ''"
                  style="color: red; size: A5">价格为0请添加备注</span>
            </template>
            <template #cell(delete)="data" v-if="readonly===0">
              <b-button
                  variant="outline-warning"
                  class="mr-1"
                  size="mini"
                  @click="delItem(itemList[data.index].item_id)"
              >
                删除
              </b-button>
            </template>

          </b-table>

          <div style="margin: 10px">
            <b-row>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <span class="text-muted">合计¥{{ subtotal }}  共{{ itemCount }}件</span>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div style="margin-top: 20px;">
        <b-col>
          <span style="font-weight: bolder">订单备注</span>
          <b-form-textarea placeholder="添加订单备注..." v-model="memo"/>
        </b-col>
      </div>
      <b-row align="end" style="margin-top: 10px">
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="success"
              class="mr-1"
              @click="save"
              v-if="readonly===0"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              class="mr-1"
              @click="cancel"
              v-if="readonly===0"
          >
            取消
          </b-button>

          <b-button
              variant="primary"
              class="mr-1"
              @click="submitOrder"
              v-if="readonly===0"
          >
            提交
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import presalesreturnboundStore from './presalesreturnboundStore'
import {useToast} from 'vue-toastification/composition'
import {
  toTime,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isNumber,
  second,
  isEmpty
} from '@core/utils/filter'
import {getUserData} from "@/auth/utils";
import {debounce} from "lodash";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      presalesreturnbound: {},
      readonly: 0,
      user: {},
      returnTime: '',
      returnboundId: 0,
      returnboundNo: '',
      storeId: 0,
      storeName: '',
      salesId: 0,
      salesName: '',
      channelId: 0,
      channelName: '',
      warehouseId: 0,
      warehouseName: '',
      reason: 0,
      itemList: [],
      productId: 0,
      productLabel: '',
      returnQty: 0,
      returnPrice: 0.00,
      subtotal: 0.00,
      itemCount: 0,
      memo: '',
      tableColumns: [
        {
          key: 'item_id',
          label: 'ID',
        },
        {
          key: 'name',
          label: '商品名称',
        },
        {
          key: 'code',
          label: '69码',
        },
        {
          key: 'specification',
          label: '规格',
        },
        {
          key: 'can_return_qty',
          label: '可退数量',
        },
        {
          key: 'return_qty',
          label: '退货数量',
        },
        {
          key: 'return_price',
          label: '退货单价',
        },
        {
          key: 'subtotal',
          label: '小计',
        },
        {
          key: 'memo',
          label: '备注',
        },
        {
          key: 'delete',
          label: '操作',
        },
      ],

      storeArray: [],
      salesmanArray: [],
      channelArray: [],//渠道客户选项
      warehouseArray: [],
      reasonArray: [],
      purchaseTeam: 0,
      purchaseTeamArray: [],//销售权
      reasonLabel: '',
      purchaseTeamLabel: '',
      productArray: [],
    }
  },
  setup() {
    const toast = useToast()

    if (!store.hasModule('presalesreturnbound')) store.registerModule('presalesreturnbound', presalesreturnboundStore)

    onUnmounted(() => {
      if (store.hasModule('presalesreturnbound')) store.unregisterModule('presalesreturnbound')
    })

    const edit = function () {
      store.dispatch('presalesreturnbound/edit', {
        id: this.id,
        storeId: this.storeId,
        channelId: this.channelId
      }).then(res => {
        if (res.data.code === 0) {
          this.channelId = res.data.data.channel_id
          this.channelName = res.data.data.channel_name
          this.itemList = res.data.data.ext.item_list
          this.itemCount = res.data.data.item_count
          this.memo = res.data.data.memo
          this.reason = res.data.data.reason
          this.reasonLabel = getCodeLabel('sales_returnbound_reasontype', res.data.data.reason)
          this.purchaseTeam = res.data.data.purchase_team_id
          this.purchaseTeamLabel = getCodeLabel('purchase_team', res.data.data.purchase_team_id)
          this.returnboundId = res.data.data.returnbound_id
          this.returnboundNo = res.data.data.returnbound_no
          this.salesId = res.data.data.sales_id
          this.salesName = res.data.data.sales_name
          this.storeId = res.data.data.store_id
          this.storeName = res.data.data.store_name
          this.subtotal = res.data.data.subtotal
          this.warehouseId = res.data.data.warehouse_id
          this.warehouseName = res.data.data.warehouse_name
          this.returnTime = toTime(res.data.data.return_time)
        } else {
          toast.error('加载订单失败')
        }
      })
    }

    const rowClass = function (item, type) {
      if (!item || type !== 'row') {
        return
      }
      if (item.ext.product_avg_price != 0) {
        return 'table-danger'
      }
    }

    const delItem = function (itemId) {
      store.dispatch('presalesreturnbound/delItem', {
        returnboundId: this.returnboundId,
        itemId: itemId
      }).then(res => {
        if (res.data.code === 0) {
          this.detail()
        } else {
          toast.error('删除失败')
        }
      })
    }

    const openSelect = function (VueSelect) {
      if (this.productArray.length > 0) {
        return VueSelect.open
      }
      return VueSelect.search.length >= 2 && VueSelect.open
    }

    const delAllItem = function () {
      if (this.itemList.length > 0) {
        store.dispatch('presalesreturnbound/delAllItem', {returnboundId: this.returnboundId}).then(res => {
          if (res.data.code === 0) {
            this.detail()
          } else {
            toast.error("清空失败")
          }
        })
      }
    }

    const view = function () {
      store.dispatch('presalesreturnbound/view', {id: this.id}).then(res => {
        this.presalesreturnbound = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      let err = 0
      let errMsg = ""
      const cRegExp = /^[1-9]\d*$/
      this.itemList.forEach(item => {
        if (isNumber(item.return_price) === 0 && item.memo === '') {
          err = 1
          errMsg = "价格为0请添加备注!"
        }
        if (!cRegExp.test(item.return_qty)) {
          errMsg = "数量必须是正整数!"
          err = 1
        }
        if (isNumber(item.return_price) < 0) {
          errMsg = "价格不能为负数!"
          err = 1
        }
      })
      if (err !== 0) {
        toast.error(errMsg)
        return false
      }
      if (this.storeId === undefined || this.storeId === "" || this.storeId == null) {
        toast.error("请选择 团队!")
        return false
      }
      if (this.salesId === undefined || this.salesId === "" || this.salesId == null) {
        toast.error("请选择 业务员!")
        return false
      }
      if (this.warehouseId === undefined || this.warehouseId === "" || this.warehouseId == null) {
        toast.error("请选择 仓库!")
        return false
      }
      if (this.returnTime === undefined || this.returnTime === "") {
        toast.error("请选择  退货日期!")
        return false
      }
      if (this.channelId === undefined || this.channelId === "" || this.channelId == null) {
        toast.error("请选择 渠道客户!")
        return false
      }
      if (this.reason === undefined || this.reason === "" || this.reason == null) {
        toast.error("请选择 退货原因!")
        return false
      }
      this.presalesreturnbound.returnbound_id = this.returnboundId
      this.presalesreturnbound.returnbound_no = this.returnboundNo
      this.presalesreturnbound.store_id = this.storeId
      this.presalesreturnbound.store_name = this.storeName
      this.presalesreturnbound.sales_id = this.salesId
      this.presalesreturnbound.sales_name = this.salesName
      this.presalesreturnbound.channel_id = this.channelId
      this.presalesreturnbound.channel_name = this.channelName
      this.presalesreturnbound.return_time = second(this.returnTime)
      this.presalesreturnbound.warehouse_id = this.warehouseId
      this.presalesreturnbound.warehouse_name = this.warehouseName
      this.presalesreturnbound.reason = this.reason
      this.presalesreturnbound.subtotal = this.subtotal
      this.presalesreturnbound.item_count = this.itemCount
      this.presalesreturnbound.memo = this.memo
      this.presalesreturnbound.source = 2
      this.presalesreturnbound.state = 0
      this.presalesreturnbound.purchaseTeamId = this.purchaseTeam
      store.dispatch('presalesreturnbound/save', this.presalesreturnbound).then(res => {
        if (res.data.code === 0) {
          toast.success('数据已保存!')
          this.$router.push({name: 'apps-presalesreturnbound-list'})
        } else {
          toast.error(res.data.error)
        }
      })
    }

    const submitOrder = function () {
      let err = 0
      let errMsg = ""
      const cRegExp = /^[1-9]\d*$/
      this.itemList.forEach(item => {
        if (isNumber(item.return_price) === 0 && item.memo === '') {
          err = 1
          errMsg = "价格为0请添加备注!"
        }
        if (!cRegExp.test(item.return_qty)) {
          errMsg = "数量必须是正整数!"
          err = 1
        }
        if (isNumber(item.return_price) < 0) {
          errMsg = "价格不能为负数!"
          err = 1
        }
      })
      if (err !== 0) {
        toast.error(errMsg)
        return false
      }
      if (this.storeId === undefined || this.storeId === "" || this.storeId == null) {
        toast.error("请选择 团队!")
        return false
      }
      if (this.salesId === undefined || this.salesId === "" || this.salesId == null) {
        toast.error("请选择 业务员!")
        return false
      }
      if (this.warehouseId === undefined || this.warehouseId === "" || this.warehouseId == null) {
        toast.error("请选择 仓库!")
        return false
      }
      if (this.returnTime === undefined || this.returnTime === "") {
        toast.error("请选择  退货日期!")
        return false
      }
      if (this.channelId === undefined || this.channelId === "" || this.channelId == null) {
        toast.error("请选择 渠道客户!")
        return false
      }
      if (this.reason === undefined || this.reason === "" || this.reason == null) {
        toast.error("请选择 退货原因!")
        return false
      }
      this.presalesreturnbound.returnbound_id = this.returnboundId
      this.presalesreturnbound.returnbound_no = this.returnboundNo
      this.presalesreturnbound.store_id = this.storeId
      this.presalesreturnbound.store_name = this.storeName
      this.presalesreturnbound.sales_id = this.salesId
      this.presalesreturnbound.sales_name = this.salesName
      this.presalesreturnbound.channel_id = this.channelId
      this.presalesreturnbound.channel_name = this.channelName
      this.presalesreturnbound.return_time = second(this.returnTime)
      this.presalesreturnbound.warehouse_id = this.warehouseId
      this.presalesreturnbound.warehouse_name = this.warehouseName
      this.presalesreturnbound.reason = this.reason
      this.presalesreturnbound.subtotal = this.subtotal
      this.presalesreturnbound.item_count = this.itemCount
      this.presalesreturnbound.memo = this.memo
      this.presalesreturnbound.source = 2
      this.presalesreturnbound.state = 0
      this.presalesreturnbound.purchaseTeamId = this.purchaseTeam

      let isOver = 0;
      for (let i = 0; i < this.itemList.length; i++) {
        if (this.itemList[i].ext.product_avg_price != 0) {
          isOver = isOver + 1;
        }
      }

      if (isOver > 0) {
        if (confirm('警告！标红商品价格可能有误，是否继续提交？')) {
          store.dispatch('presalesreturnbound/submit', this.presalesreturnbound).then(res => {
            if (res.data.code === 0) {
              this.readonly = 1
              toast.success('数据已保存!')
              this.$router.push({name: 'apps-presalesreturnbound-list'})
            } else {
              let data = JSON.parse(res.data.data)
              if (isEmpty(data[0].product_name) && isEmpty(data[0].product_id)) {
                toast.error(data)
              } else {
                let result = []
                for (let i in data) {
                  let return_qty = data[i].return_qty
                  let can_return_qty = data[i].can_return_qty
                  let product_id = data[i].product_id
                  let product_name = data[i].product_name
                  let error = "ID:" + product_id + ",商品名称:" + product_name + ",本次退货数量:" + return_qty + ",可退数量:" + can_return_qty
                  result.push(error);
                }
                toast.error(JSON.stringify(result))
              }
              this.refetchData()
            }
          })
        }
      } else {
        store.dispatch('presalesreturnbound/submit', this.presalesreturnbound).then(res => {
          if (res.data.code === 0) {
            this.readonly = 1
            toast.success('数据已保存!')
            this.$router.push({name: 'apps-presalesreturnbound-list'})
          } else {
            let data = JSON.parse(res.data.data)
            if (isEmpty(data[0].product_name) && isEmpty(data[0].product_id)) {
              toast.error(data)
            } else {
              let result = []
              for (let i in data) {
                let return_qty = data[i].return_qty
                let can_return_qty = data[i].can_return_qty
                let product_id = data[i].product_id
                let product_name = data[i].product_name
                let error = "ID:" + product_id + ",商品名称:" + product_name + ",本次退货数量:" + return_qty + ",可退数量:" + can_return_qty
                result.push(error);
              }
              toast.error(JSON.stringify(result))
            }
            this.refetchData()
          }
        })
      }
    }


    const reload = function () {
      store.dispatch('presalesreturnbound/edit', {
        id: 0,
        storeId: this.storeId,
      }).then(res => {
        if (res.data.code === 0) {
          this.returnboundId = res.data.data.returnbound_id
          this.returnboundNo = res.data.data.returnbound_no
          this.reason = this.reasonArray[0].value
          this.purchaseTeam = this.purchaseTeamArray[0].value
          this.reasonLabel = this.reasonArray[0].label
          this.purchaseTeamLabel = this.purchaseTeamArray[0].label
        } else {
          toast.error('订单加载失败')
        }
      })
    }

    const loadStoreList = function () {
      store.dispatch('presalesreturnbound/loadStoreList', {userId: this.user.user_id, type: 1}).then(res => {
        if (res.data.code !== 0) {
          toast.error('加载团队失败!')
          return
        }
        if (res.data.data.length < 1) {
          toast.error('您当前不属于任何团队!')
          return
        }
        let list = []
        res.data.data.forEach(item => {
          list.push({
            value: item,
            label: item.store_name,
          })
        })
        this.storeArray = list
        if (this.returnboundId === 0) {
          this.storeId = res.data.data[0].store_id
          this.storeName = res.data.data[0].store_name
        }
        this.loadSalesManList()
      })
    }

    const loadSalesManList = function () {
      store.dispatch('presalesreturnbound/loadSalesManList', {storeId: this.storeId}).then(res => {
        if (res.data.code !== 0) {
          this.salesmanArray = []
          toast.error('加载业务员失败!')
          return
        }
        if (res.data.data.length < 1) {
          this.salesId = 0
          this.salesName = ''
          this.salesmanArray = []
          return
        } else {
          let list = []
          res.data.data.forEach(item => {
            list.push({
              value: item,
              label: item.full_name,
            })
          })
          this.salesmanArray = list
          if (this.id === 0) {
            this.salesName = getCodeLabel('user', this.user.user_id)
            this.salesId = this.user.user_id
          }
        }
      })
    }

    const loadChannelList = debounce(function (searchKey, callback) {
      if (searchKey !== '') {
        store.dispatch('presalesreturnbound/loadChannelList', {
          type: 6,
          start: 1,
          limit: 20,
          storeId: this.storeId,
          status: 4,
          order_by: 'company_id',
          order_desc: 'desc',
          searchKey: searchKey.replaceAll("'", ""),
        }).then(res => {
          if (res.data.code === 0) {
            let list = []
            res.data.data.list.forEach(item => {
              list.push({
                value: item,
                label: item.company_name
              })
            })
            this.channelArray = list
          }
        })
      }
      callback(false)
    }, 500)

    const loadProductOptions = debounce(function (searchKey) {
      if (searchKey !== '' && searchKey.length >= 2) {
        this.productArray = []
        store.dispatch('presalesreturnbound/loadProductList', {
          start: 1,
          limit: 30,
          name: searchKey.replaceAll("'", ""),
          order_by: 'name',
          storeId: this.storeId,
          channelId: this.channelId,

        }).then(res => {
          if (res.data.code !== 0) {
            toast.error('加载产品失败!')
            return
          }
          // if (res.data.data.list.length < 1) {
          //   toast.error('未找到该类产品!')
          //   return
          // }
          this.productArray = res.data.data.list
        })
      }
    },500)

    const loadWarehouseOptions = function () {
      store.dispatch('presalesreturnbound/loadWarehouseList', {storeId: this.storeId}).then(res => {
        if (res.data.code !== 0) {
          this.warehouseArray = []
          toast.error('加载仓库失败!')
          return
        }
        if (res.data.data.length < 1) {
          this.warehouseId = 0
          this.warehouseName = ''
          this.warehouseArray = []
          return
        }
        let list = []
        res.data.data.forEach(item => {
          list.push({
            value: item,
            label: item.warehouse_name,
          })
        })
        //新建状态下，默认选中仓库
        this.warehouseArray = list
        if (this.id === 0) {
          this.warehouseId = res.data.data[0].warehouse_id
          this.warehouseName = res.data.data[0].warehouse_name
        }
      })
    }

    const quickAdd = function () {
      if (this.channelId === 0) {
        toast.error('请选择 客户!')
        return false
      }
      store.dispatch('presalesreturnbound/addItem', {
        returnboundId: this.returnboundId,
        productId: this.productId,
        returnQty: 1,
        returnPrice: this.returnPrice,
      }).then(res => {
        if (res.data.code === 0) {
          this.detail()
        } else {
          toast.error('添加明细失败')
        }
      })
    }

    const updateItem = function (item, index, event) {
      let err = 0
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(item.return_qty)) {
        toast.error('售卖数量必须是正整数!')
        err = 1
      }
      if (isNumber(item.return_price) < 0) {
        toast.error("价格不能为负数")
        err = 1
      }
      if (err !== 0) {
        return false
      }
      store.dispatch('presalesreturnbound/updateItem', {
        returnboundId: this.returnboundId,
        itemId: item.item_id,
        returnQty: item.return_qty,
        returnPrice: item.return_price,
      }).then(res => {
        if (res.data.code !== 0) {
          toast.error('更新数量/价格失败')
        } else {
          this.subtotal = res.data.data.ext.preSalesReturnbound.subtotal
          this.itemCount = res.data.data.ext.preSalesReturnbound.item_count
          this.itemList[index].subtotal = res.data.data.subtotal
          this.detail()
        }
      })
    }

    const addItemMemo = function (item, event) {
      store.dispatch('presalesreturnbound/addItemMemo', {
        itemId: item.item_id,
        memo: event.target.value,
      }).then(res => {
        if (res.data.code !== 0) {
          toast.error('添加备注失败')
        }
      })
    }

    const detail = function () {
      store.dispatch('presalesreturnbound/edit', {
        id: this.returnboundId,
        storeId: this.storeId,
        channelId: this.channelId
      }).then(res => {
        if (res.data.code === 0) {
          this.itemList = res.data.data.ext.item_list
          this.subtotal = res.data.data.subtotal
          this.itemCount = res.data.data.item_count
        } else {
          toast.error('加载订单明细失败')
        }
      })
    }

    const changeSelect = function (key, event) {
      if (key === 'store') {
        if (this.storeId !== event.value.store_id && this.itemList.length > 0) {
          this.delAllItem()
        }
        this.storeId = event.value.store_id
        this.storeName = event.value.store_name
      } else if (key === 'salesman') {
        this.salesId = event.value.user_id
        this.salesName = event.value.full_name
        this.purchaseTeam = event.value.default_purchase_team_id
        this.purchaseTeamLabel = getCodeLabel('purchase_team', event.value.default_purchase_team_id)
      } else if (key === 'channel') {
        this.channelId = event.value.company_id
        this.channelName = event.value.company_name
      } else if (key === 'sales_warehouse') {
        this.warehouseId = event.value.warehouse_id
        this.warehouseName = event.value.warehouse_name
      } else if (key === 'product' && event !== null) {
        this.productId = event.product_id
        this.returnQty = 1
        this.returnPrice = isNumber(event.ext.returnPrice)
      } else if (key === 'reason') {
        this.reason = event.value
        this.reasonLabel = event.label
      } else if (key === 'purchase_team') {
        this.purchaseTeam = event.value
        this.purchaseTeamLabel = event.label
      }
    }

    return {
      edit,
      view,
      cancel,
      save,
      submitOrder,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      loadStoreList,
      changeSelect,
      loadSalesManList,
      loadChannelList,
      loadWarehouseOptions,
      reload,
      loadProductOptions,
      openSelect,
      quickAdd,
      updateItem,
      addItemMemo,
      detail,
      delItem,
      delAllItem,
      rowClass,
      isEmpty


    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.storeId = this.$route.params.storeId || 0;
    this.channelId = this.$route.params.channelId || 0;
    this.reasonArray = getCodeOptions('sales_returnbound_reasontype')
    this.purchaseTeamArray = getCodeOptions('purchase_team')
    const userData = getUserData()
    this.purchaseTeam = userData.default_purchase_team_id
    this.user = userData
    if (this.id === 0) {
      this.returnTime = new Date()
      this.loadStoreList()
    } else {
      this.edit()
    }
    this.readonly = this.$route.query.readonly || 0;
    if (this.$route.query.readonly===0||this.id===0){
      this.readonly=0
    }
  },

  computed: {
    storeChange() {
      return {
        store_id: this.storeId,
        store_name: this.storeName,
      }
    },
    warehouseIdChange() {
      return {
        warehouse_id: this.warehouseId,
        warehouse_name: this.warehouseName,
      }
    },
  },

  watch: {
    storeChange: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          //当团队发生改变
          if (this.id === 0) {
            // 新建状态 则重新生成单号 和订单id
            this.reload()
            this.channelId = 0
            this.channelName = ''
            this.productArray = []//商品选项
            this.productLabel = ''
            this.productId = ''
          }
          //加载团队下的业务员
          this.loadSalesManList()
          this.loadWarehouseOptions()

        }
      },
      deep: true,
    },
    warehouseIdChange: {
      handler(newValue, oldValue) {
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
